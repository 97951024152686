<template>
    <div class="page1">
        <EForm
                :formColumns="formColumns"
                :rowSize="4"
                :optionsBtn="true"
                :actionBtn="false"
                :formData="searchForm"
                ref="form"
                @onSearch="onSearch"
                :searchFlag="true"
                :exportShow="false"
        ></EForm>
        <EButton @click="dialogVisible=true">新增</EButton>
        <ETable :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="true"
                :count="count"
                @changePage="changePage"
                @changeSize="changeSize"
                :page="searchForm.current"
                :page_size="searchForm.size">
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="180px"
            >
                <template slot-scope="scope">
                    <div>
                        <EButton type="text" @click="editDialog(scope.row)">修改</EButton>
                        <!--                        <el-button type="text " @click="userDetail(scope.row.user_id)">编辑-->
                        <!--                        </el-button>-->
                    </div>
                </template>
            </el-table-column>
        </ETable>
        <EDialog :dialogVisible="dialogVisible" :title="title"
                 @handleClose="handleClose"
                 width="60%"
                 @handleClick="saveData"
        >
            <div class="title">
                <i class="el-icon-user-solid"></i>
                <span class="pl10">基础信息</span>
            </div>
            <el-form label-position="right" label-width="100px" :model="form" :rules="formRules" ref="form">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="登录账号" prop="username">
                            <el-input v-model="form.username">

                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮箱" prop="email">
                            <el-input v-model="form.email">

                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="用户姓名" prop="name">
                            <el-input v-model="form.name">

                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="手机号" prop="mobile">
                            <el-input v-model="form.mobile">

                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="密码" prop="password">
                            <el-input v-model="form.password" type="password">

                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="确认密码" prop="confirmPassword">
                            <el-input v-model="form.confirmPassword" type="password">

                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="状态" prop="status">
                            <el-radio-group v-model="form.status">
                                <el-radio :label="1">正常</el-radio>
                                <el-radio :label="0">禁用</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="title">
                <i class="el-icon-s-custom"></i>
                <span class="pl10">职责信息</span>
            </div>
            <el-form label-position="right" label-width="100px" :model="form" :rules="formRules" ref="form2">
                <el-row>
                    <!--                    <el-col :span="12">-->
                    <!--                        <el-form-item label="用户编号" prop="name">-->
                    <!--                            <el-input v-model="form.name">-->

                    <!--                            </el-input>-->
                    <!--                        </el-form-item>-->
                    <!--                    </el-col>-->
                    <el-col :span="12">
                        <el-form-item label="所属角色" prop="roleId">
                            <el-select v-model="form.roleId" placeholder="请选择"
                                       style="width: 100%">
                                <el-option
                                        v-for="item in roleOptions"
                                        :key="item.id"
                                        :label="item.roleName"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="所属部门" prop="deptId">
                            <el-select v-model="form.deptId" placeholder="请选择"
                                       style="width: 100%">
                                <el-option
                                        v-for="item in deptOptions"
                                        :key="item.id"
                                        :label="item.deptName"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import vxRule from "@/assets/js/formValidate";
  import {mapGetters} from 'vuex'

  export default {
    name: 'Login',
    data() {
      return {
        formColumns: [
          {
            title: '用户姓名',
            type: 'text',
            property: 'name',
            placeHolder: '请输入',
            show: true,
          },
          {
            title: '登录名',
            type: 'text',
            property: 'username',
            placeHolder: '请输入',
            show: true,
          },],
        tableTitle: [
          {
            label: '登录名',
            prop: 'username',
          },
          {
            label: '用户姓名',
            prop: 'name',
          },
          {
            label: '所属角色',
            prop: 'roleName',
          }, {
            label: '所属部门',
            prop: 'deptName',
          }
        ],
        tableData: [],
        loading: false,
        dialogVisible: false,
        title: '添加账号',
        form: {
          name: '',
          username: '',//登录账号
          email: '',//邮箱
          mobile: '',//手机号
          status: 1,//状态
          password: '',//密码
          roleId: '',//角色ID
          deptId: '',//部门ID
          confirmPassword: ''
        },
        searchForm: {
          name: '',
          username: '',
          current: 1,
          size: 10
        },
        cascader: false,
        role_id: [],
        options: [],
        count: null,
        formRules: {
          username: [vxRule(true, '', "blur", "登录账号不能为空")],
          name: [vxRule(true, '', "change", "用户姓名不能为空"),
          ],
          mobile: [vxRule(true, '', "blur", "联系电话不能为空"),
            vxRule(true, "Mobile", "blur", "请输入正确的手机号"),],
          password: [vxRule(true, '', "blur", "密码不能为空")],
          confirmPassword: [vxRule(true, '', "blur", "密码不能为空"),
            vxRule(true, val => {
              return {result: val === this.form.password, errMsg: "确认密码与密码不一致"}
            })],
          roleId: [vxRule(true, '', "blur", "所属角色不能为空")],
          deptId: [vxRule(true, '', "blur", "所属部门不能为空")],
        },
        labelPosition: 'right',
        labelWidth: '100px',
        roleOptions: [],//角色下拉列表
        deptOptions: [],//部门下拉列表
      }
    },
    computed: {
      ...mapGetters(['userInfo']),
    },
    watch: {},
    created() {
      this.getData()
      this.getOptions()
    },
    components: {ETable, EDialog, EButton, EForm},
    beforeMount() {

    },
    methods: {
      async getOptions() {
        console.log(this.searchForm)
        let res = await Http.roleList()
        if (res.code == 200) {
          this.roleOptions = res.data
        }
        let res2 = await Http.deptList()
        if (res.code == 200) {
          this.deptOptions = res2.data
        }
      },
      onSearch() {
        this.searchForm.current = 1
        this.getData()
      },
      changePage(page) {
        this.searchForm.current = page
        this.getData()
      },
      changeSize(size) {
        this.searchForm.size = size
        this.getData()
      },
      handleClose() {
        this.title = '添加账号'
        this.dialogVisible = false
        this.form = {
          id: '',
          name: '',
          username: '',//登录账号
          email: '',//邮箱
          mobile: '',//手机号
          status: 1,//状态
          password: '',//密码
          roleId: '',//角色ID
          deptId: '',//部门ID
        }
        this.role_id = []
        this.$refs.form.resetFields()
        this.$refs.form2.resetFields()
      },
      getParent() {
        this.form.role_id = this.role_id[this.role_id.length - 1]
      },
      async getData() {
        let res = await Http.userLists(this.searchForm)
        if (res.code == 200) {
          this.tableData = res.data.records
          this.count = res.data.total
        }

      },
      saveData() {
        let flag = false
        let flag2 = false
        this.$refs['form'].validate((valid, object) => {
          if (valid && !this.error) {
            flag = true
          } else {
            return false;
          }


        });
        this.$refs['form2'].validate((valid2, object) => {
          if (valid2 && !this.error) {
            flag2 = true

          } else {
            return false;
          }
        })
        if (flag && flag2) {
          this.addBtn()
        }
      },
      async addBtn() {
        let {
          id,
          name,
          username,
          email,
          mobile,
          status,
          password,
          roleId,
          deptId
        } = this.form
        let res = await Http.userSubmit({
          id,
          name,
          username,
          email,
          mobile,
          status,
          password,
          roleId,
          deptId
        })
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.handleClose()
          this.getData()
        }
      },
      editDialog(data) {
        this.title = '编辑账号'
        this.form = {
          id: data.id,
          name: data.name,
          username: data.username,//登录账号
          email: data.email,//邮箱
          mobile: data.mobile,//手机号
          status: Number(data.status),//状态
          password: '',//密码
          roleId: Number(data.roleId),//角色ID
          deptId: data.deptId,//部门ID
        }
        this.dialogVisible = true
      },
    }
  }
</script>

<style lang="scss" scoped>
    .title {
        font-size: 16px;
        padding: 10px 0;
        border-bottom: 1px solid #DCDFE6;
        margin-bottom: 20px;
        color: #333;
        font-weight: bold;

    }
</style>

